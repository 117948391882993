import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footersection from "./Footersection";
import Footer from "./Footer";

import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100vw",
    overflow: "hidden",
    // background:
    //   "linear-gradient(115deg, rgba(252,255,249,1) 0%, rgba(173,231,197,1) 100%)",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
    "": {},
  },
  shade3: {
    position: "absolute",
    left: "auto",
    right: "-10%",
    top: "30%",

    transform: "translateY(-50%)",
    zIndex: " -1",
    width: " 900px",
    height: "1200px",
    borderRadius: "6000px",
    backgroundImage:
      "radial-gradient(circle, rgba(0,212,255,1) 33%, rgba(115,211,18,1) 43%)",
    opacity: "0.55",
    // -webkit-filter: blur(100px);
    filter: "blur(200px)",
    [theme.breakpoints.down("xs")]: {
      right: "-80px",
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.shade3}></div>
      <TopBar />
      <div
        style={
          history.location.pathname == "/"
            ? { display: "block" }
            : { display: "block" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
      {/* <Footersection/> */}
    </div>
  );
};

export default MainLayout;
