export const NetworkContextName = "QIE-Chain";
// export const ACTIVE_NETWORK = 9731; //TestNet
// export const mlmContractAddress = "0x04Bd06190F3A2B60DE0fd57167E8857ECe1c7961"; //testNet
// export const RPC_URL = "https://rpc.qiblockchain.online/"; //testNet
export const ACTIVE_NETWORK = 5656; //MainNet
export const mlmContractAddress = "0x2C5aF4aa2bC321b7Efbb9752f1A32392aa3fcDe3"; //mainNet
export const RPC_URL = "https://rpc-main2.qiblockchain.online/"; //mainNet
export const deadAddress = "0x0000000000000000000000000000000000000000";

// export const NetworkDetails = [
//   {
//     chainId: "0x2603",
//     chainName: "QIE Testnet",
//     nativeCurrency: {
//       name: "QIE",
//       symbol: "QIE",
//       decimals: 18,
//     },
//     rpcUrls: [RPC_URL],
//     blockExplorerUrls: [
//       "https://testnet.qiblockchain.online/explorer/explorer",
//     ],
//   },
// ];
export const NetworkDetails = [
  {
    chainId: "0x1618",
    chainName: "QIE Mainnet",
    nativeCurrency: {
      name: "QIE",
      symbol: "QIE",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: [
      "https://mainnet.qiblockchain.online/explorer/explorer",
    ],
  },
];
